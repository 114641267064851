
<script>
import PageableList from './PageableList.vue'

export default {
  extends: PageableList,
  data() {
    return {
      record: null,
      deleteRecordDialog: false
    }
  },
  methods: {
    confirmDeleteRecord(record) {
      this.record = record
      this.deleteRecordDialog = true
    },
    async deleteRecord() {
      if (this.record) {
        try {
          await this.service.remove(this.record.id);
          this.record = null;
          this.page = 1;
          this.deleteRecordDialog = false;
          this.loadRecords();
          this.$toast.add({
            severity: 'success',
            summary: 'Registro excluído com sucesso!',
            life: 3000
          });
        } catch (err) {
          console.error(err)
          this.$toast.add({
            severity: 'error',
            summary: 'Erro ao excluir o registro!',
            detail: err?.response?.data?.message,
            life: 3000
          })
        }
      }
    }
  }
}
</script>
