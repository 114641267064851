
<script>
import List from './List.vue'

export default {
  extends: List,
  data() {
    return {
      page: 1,
      perPage: 10,
      totalItems: null,
      totalPages: null,
    }
  },
  methods: {
    async loadRecords() {
      const { data } = await this.service.findAll({
        page: this.page,
        limit: this.perPage,
        ...this.filters
      })
      this.records = data.items
      this.totalItems = data.meta?.totalItems
      this.totalPages = data.meta?.totalPages
    },
    onPage(event) {
      this.page = event.page + 1;
      this.loadRecords();
    }
  }
}
</script>
