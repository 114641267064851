
<script>
export default {
  data() {
    return {
      service: null,
      filters: {},
      sort: null,
      records: [],
    }
  },
  mounted() {
    if (!this.service) {
      throw new Error('Inicie o service antes do método mounted. Exemplo: `this.service = new CrudService("/admin/dividas")`');
    }
    this.loadRecords();
  },
  methods: {
    async loadRecords() {
      const { data } = await this.service.findAll({ ...this.filters, sort: this.sort });
      this.records = data;
    }
  }
}
</script>
